/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css"

function Footer() {
  return (
    <footer className={footerStyle}>
       <p>צרו עימנו קשר במייל: barpinian1213@gmail.com</p>
       <p>&copy; 2024 BP Motors. All rights reserved.</p>
    </footer>
  );
}

const footerStyle = css`
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
`;

export default Footer;
