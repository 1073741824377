/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from "@emotion/css";

function ImportCalculator() {
  // State variables for inputs
  const [carPrice, setCarPrice] = useState('');

  // State variables for radio buttons
  const [engineType, setEngineType] = useState(''); // Europe or USA
  const [importSource, setImportSource] = useState(''); // Europe or USA
  const [originalDocs, setOriginalDocs] = useState(''); // Yes or No
  const [manufacturedInUSA, setManufacturedInUSA] = useState(''); // Yes or No

  // Function to calculate the import fee
  const calculateImportFee = () => {
  };

  const handleCalculate = () => {
    const data = {
      carPrice,
      engineType,
      importSource,
      originalDocs,
      manufacturedInUSA,
      totalFee: calculateImportFee(),
    };

    // You can send this data to an API or use it as needed
    console.log("Submitted Data:", data);

    const engineTypeDisplay = engineType === 'Electric'
    ? 'חשמלי (אירופה)'
    : engineType === 'GasolinOrDiesel'
    ? 'בנזין/דיזל (ארה"ב)'
    : engineType === 'PlugInOrHybrid'
    ? 'פלאג אין/הייברידי'
    : 'לא נבחר';

  alert(`נתונים נשלחו בהצלחה:
      מחיר רכב: ${carPrice} ש"ח
      סוג הנעה: ${engineTypeDisplay}
      מקור ייבוא: ${importSource === 'Europe' ? 'אירופה' : 'ארה"ב'}
      מסמך מקורי: ${originalDocs === 'Yes' ? 'כן' : 'לא'}
      מיוצרת בארה"ב: ${manufacturedInUSA === 'Yes' ? 'כן' : 'לא'}
      עלות כוללת: ${data.totalFee} ש"ח
    `);
  };

  return (
    <div className={calculatorStyle}>
      <h2>מחשבון ייבוא רכב (Car Import Fee Calculator)</h2>
      <p>כאן תוכלו לחשב את עלות הרכב הכוללת !</p>

      {/* Radio buttons for electric car */}
      <div className={radioContainerStyle}>
        <label>בחר סוג הנעה:</label>
        <div>
          <label>
            <input
              type="radio"
              name="engineType"
              value="Electric"
              checked={engineType === 'Electric'}
              onChange={(e) => setEngineType(e.target.value)}
            />
            חשמלי
          </label>
          <label>
            <input
              type="radio"
              name="engineType"
              value="GasolinOrDiesel"
              checked={engineType === 'GasolinOrDiesel'}
              onChange={(e) => setEngineType(e.target.value)}
            />
            בנזין/דיזל
          </label>
          <label>
            <input
              type="radio"
              name="engineType"
              value="PlugInOrHybrid"
              checked={engineType === 'PlugInOrHybrid'}
              onChange={(e) => setEngineType(e.target.value)}
            />
            פלאג אין/הייברידי
          </label>
        </div>
      </div>


      {/* Radio buttons for import source */}
      <div className={radioContainerStyle}>
        <label>מכונית בייבוא אישי מ:</label>
        <div>
          <label>
            <input
              type="radio"
              name="importSource"
              value="Europe"
              checked={importSource === 'Europe'}
              onChange={(e) => setImportSource(e.target.value)}
            />
            אירופה
          </label>
          <label>
            <input
              type="radio"
              name="importSource"
              value="USA"
              checked={importSource === 'USA'}
              onChange={(e) => setImportSource(e.target.value)}
            />
            ארה"ב
          </label>
        </div>
      </div>

      {/* Radio buttons for original documents */}
      <div className={radioContainerStyle}>
        <label>יש למכונית מסמך מקורי?:</label>
        <div>
          <label>
            <input
              type="radio"
              name="originalDocs"
              value="Yes"
              checked={originalDocs === 'Yes'}
              onChange={(e) => setOriginalDocs(e.target.value)}
            />
            כן
          </label>
          <label>
            <input
              type="radio"
              name="originalDocs"
              value="No"
              checked={originalDocs === 'No'}
              onChange={(e) => setOriginalDocs(e.target.value)}
            />
            לא
          </label>
        </div>
      </div>

      {/* Radio buttons for manufactured in the USA */}
      <div className={radioContainerStyle}>
        <label>האם המכונית מיוצרת בארה"ב?:</label>
        <div>
          <label>
            <input
              type="radio"
              name="manufacturedInUSA"
              value="Yes"
              checked={manufacturedInUSA === 'Yes'}
              onChange={(e) => setManufacturedInUSA(e.target.value)}
            />
            כן
          </label>
          <label>
            <input
              type="radio"
              name="manufacturedInUSA"
              value="No"
              checked={manufacturedInUSA === 'No'}
              onChange={(e) => setManufacturedInUSA(e.target.value)}
            />
            לא
          </label>
        </div>
      </div>

      {/* Input for car price */}
      <div className={inputContainerStyle}>
        <label>מחיר הרכב (Car Price):</label>
        <input
          type="number"
          value={carPrice}
          onChange={(e) => setCarPrice(e.target.value)}
          placeholder="Enter car price"
        />
      </div>

      {/* Button to submit data */}
      <button onClick={handleCalculate} className={buttonStyle}>
        חשב
      </button>

      {/* Display the calculated import fee */}
      <div className={resultStyle}>
        <p>עלות כוללת (Total Import Fee): <strong>{calculateImportFee()} ש"ח</strong></p>
      </div>
    </div>
  );
}

// Emotion CSS for the page
const calculatorStyle = css`
  padding: 20px;
  text-align: center;
`;

const inputContainerStyle = css`
  margin-bottom: 20px;
  text-align: right;

  label {
    margin-right: 10px;
    font-size: 1.2rem;
  }

  input {
    width: 200px;
    padding: 8px;
    font-size: 1rem;
  }
`;

const radioContainerStyle = css`
  margin-bottom: 20px;
  text-align: right;

  label {
    margin-right: 10px;
    font-size: 1.2rem;
  }

  input {
    margin-left: 5px;
  }

  div {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
`;

const buttonStyle = css`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const resultStyle = css`
  margin-top: 20px;
  font-size: 1.5rem;
  color: green;
`;

export default ImportCalculator;
