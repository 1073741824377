/** @jsxImportSource @emotion/react */
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Cars from './pages/CarsInformation';
import Contact from './pages/Contact';
import ImportCalculator from './pages/ImportCalculator';
import CarsInformation from './pages/CarsInformation';
import { css } from '@emotion/css';

function App() {
  return (
    <Router>
      <div className={appStyle}>
        <Header />
        <div className={contentStyle}>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="home" element={<Home />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/import-calculator" element={<ImportCalculator />} />
            <Route path="/cars-information" element={<CarsInformation />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

const appStyle = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
`;

const contentStyle = css`
  flex: 1; /* Pushes the footer to the bottom */
`;

export default App;
