import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getCarInformation = async (carId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/car-information/${carId}`, {
      headers: { "car-id": carId },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching car information:", error);
    throw error;
  }
};
