/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css";
import React, { useState } from "react";
import { getCarInformation } from "../services/apiService";

const fieldTranslations = {
  id: "מזהה רכב",
  licensePlate: "מספר רכב",
  manufacturerCode: "קוד יצרן",
  modelType: "סוג דגם",
  manufacturerName: "יצרן",
  modelCode: "קוד דגם",
  modelName: "שם דגם",
  trimLevel: "רמת גימור",
  safetyEquipmentLevel: "רמת אבזור בטיחותי",
  pollutionGroup: "קבוצת זיהום",
  productionYear: "שנת ייצור",
  engineModel: "דגם מנוע",
  lastInspectionDate: "מועד טסט אחרון",
  validityDate: "טסט עד לתאריך",
  ownership: "סוג בעלות",
  chassis: "מספר שלדה",
  colorCode: "קוד צבע",
  carColor: "צבע רכב",
  frontTire: "מידות צמיג קדמי",
  rearTire: "מידות צמיג אחורי",
  fuelType: "סוג מנוע",
  registrationInstruction: "הוראת רישום",
  roadEntryDate: "תאריך עלייה לכביש",
  commercialAlias: "דגם",
  rank: "דירוג",
  disabledDriver: "בעל תו נכה",
};

function CarsInformation() {
  const [carNumber, setCarNumber] = useState("");
  const [carData, setCarData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    if (!carNumber || isNaN(Number(carNumber)) || Number(carNumber) <= 0) {
      setError("מספר רכב אינו תקין");
      return;
    }

    setError("");
    setIsLoading(true);
    try {
      const data = await getCarInformation(carNumber);
      setCarData(data);
    } catch (err) {
      console.error("Error fetching car information:", err);
      setError(err.response?.data?.error || "אירעה שגיאה בקבלת המידע");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={containerStyle}>
      <h2>חיפוש מידע על רכב</h2>
      <div className={inputContainerStyle}>
        <label htmlFor="carNumber">מספר רכב:</label>
        <input
          type="number"
          id="carNumber"
          value={carNumber}
          onChange={(e) => setCarNumber(e.target.value)}
          className={inputStyle}
        />
      </div>
      <button onClick={handleSearch} className={buttonStyle}>
        חפש
      </button>
      {isLoading && <p>טוען נתונים...</p>}
      {error && <p className={errorStyle}>{error}</p>}
      {carData && (
        <div className={resultStyle}>
          <h3 className={headerStyle}>תוצאות חיפוש:</h3>
          <table className={tableStyle}>
            <thead>
              <tr>
                <th>מאפיין</th>
                <th>ערך</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(carData.carInformation).map(([key, value]) => (
                <tr key={key}>
                  <td>{fieldTranslations[key] || key}</td>
                  <td>
                    {key === "disabledDriver"
                      ? value
                        ? "כן"
                        : "לא"
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

// Emotion CSS
const containerStyle = css`
  padding: 20px;
  direction: rtl; /* Ensures RTL layout */
  text-align: right; /* Aligns all text to the right */
  font-family: Arial, sans-serif; /* Optional: Use a font that supports Hebrew */
`;


const inputContainerStyle = css`
  margin: 20px 0;
`;

const inputStyle = css`
  margin-left: 10px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const buttonStyle = css`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const errorStyle = css`
  color: red;
  margin-top: 10px;
`;

const resultStyle = css`
  margin-top: 20px;
  text-align: right; /* Align all content to the right */
  direction: rtl; /* Ensure RTL layout for the entire result section */
`;

const headerStyle = css`
  text-align: right; /* Align header text to the right */
  direction: rtl; /* Ensure proper RTL layout */
  font-size: 1.5em; /* Optional: Adjust font size for better appearance */
`;

const tableStyle = css`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  direction: rtl; /* Ensure RTL layout */
  text-align: right; /* Align text in cells to the right */

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;


export default CarsInformation;
